import React, { useContext, useState } from "react";
import { storeContext } from "./../global/store";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import StoreIcon from "@material-ui/icons/Store";
import CloseIcon from "@material-ui/icons/Close";
import { CSSTransition } from "react-transition-group";
import { deleteTheUserAndTokenFromStorage } from "./../services/auth";
import { IconContext } from "react-icons";
import { AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { FaChalkboardTeacher, FaListAlt } from "react-icons/fa";
import { TiShoppingCart } from "react-icons/ti";
import { CgProfile } from "react-icons/cg";
import { FaSellsy } from "react-icons/fa";

import cn from "classnames";

const Header = () => {
  const [open, setOpen] = useState(false);

  const { store, setStore } = useContext(storeContext);
  const [menuOpen, setMenuOpen] = useState(false);

  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "x-sm:text-xs md:text-sm 2xl:text-2xl bg-farasha-purple flex flex-col items-center justify-center space-y-2 md:w-28 2xl:w-48 rounded-lg x-sm:p-1 md:p-2 2xl:p-4 absolute hidden",
    { dropdownContent: menuOpen }
  );

  return (
    <div className="header">
      <div
        style={{ zIndex: "1000" }}
        className="hidden hidden__nav nav__item w-full bg-white p-2 md:flex flex-col-reverse items-center md:flex-row md:justify-around xl:space-x-10  lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 lg:px-8 xl:px-12"
      >
        <div className="flex-45 flex flex-col md:flex-row xl:space-x-10 lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 items-center">
          {
            /*
            <a
            target="_blank"
            rel="noreferrer"
            href="https://store.farashawake.com/"
            className=" cursor-pointer bg-orange py-2 px-6  rounded-4xl font-black text-primary text-white text-base space-x-1"
          >
            <StoreIcon />
            <span>المتجر</span>
          </a>
            */
          }
          {!store.isLogged && (
            <a className="bg-blue-night text-white rounded-full p-2 flex flex-row x-sm:mt-4 md:mt-0"
            href="https://wa.me/213558318841"
          >إنضم إلينا</a>
            
          )}

          <div className="text-white">---------</div>
        </div>

        <Link to="/" className="hidden md:block md:flex-10 ">
          <img src="/images/logo.png" alt="Logo" className=" block mx-auto" />
        </Link>
        <div className="flex-45">
          <div className="text-farasha-purple flex justify-end flex-col-reverse md:flex-row xl:space-x-10 lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 text-sm xl:text-base 2xl:text-xl items-center">
            <div>
              <p>Les Architectes de la réalité</p>
              <p>Modeling your life</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden px-6 py-4 shadow-md items-center bg-white">
        <div className=" flex flex-50 justify-start">
          <img src="/images/logo.png" alt="logo" className="h-20 w-20" />
          <div className="flex flex-50 justify-end items-center">
            <div className="text-farasha-purple">
              <p>Les Architectes de la réalité</p>
              <p>Modeling your life</p>
            </div>
          </div>
        </div>
      </div>
      <CSSTransition
        in={open}
        timeout={500}
        classNames="side-bar"
        unmountOnExit
        className="fixed h-screen w-9/12 bg-white top-0 left-0 max-w-xs "
        style={{
          zIndex: "999",
          boxShadow: "0px 24px 17.888px rgba(28, 153, 228, 0.06)",
        }}
      >
        <div className="">
          <IconButton
            style={{ position: "absolute", right: 0 }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <div className="text-farasha-purple pt-20 flex flex-col items-center space-y-6">
            <Link to="/" className="text-center">
              الرئيسية
            </Link>
            <Link to="/free-courses" className="text-center">
              دورات مجانية
            </Link>
            <Link to="/paid-courses" className="text-center">
              دورات مدفوعة
            </Link>
            <Link to="/articles" className="text-center">
              مقالات
            </Link>
            <Link to="/about-us" className="text-center">
              من نحن
            </Link>
            {!store.isLogged && (
              <Link
                to="/login"
                className=" cursor-pointer bg-blue-night py-2 px-6  rounded-4xl font-black text-primary	text-white text-base"
              >
                انضم إلينا
              </Link>
            )}
            {store.isLogged && (
              <Link to="/cart">
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#492b83" } }}
                >
                  <TiShoppingCart></TiShoppingCart>
                </IconContext.Provider>
              </Link>
            )}
            {store.isLogged && (
              <div className="hover:text-gray-600">
                <div className={menuStyle}>
                  <div
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <IconContext.Provider
                      value={{ size: "2em", style: { color: "#492b83" } }}
                    >
                      <CgProfile></CgProfile>
                    </IconContext.Provider>
                    {/* <span>الملف الشخصي</span> */}
                  </div>
                  <div className={menuItemsStyle}>
                    <Link
                      to="/settings"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">اعدادات</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <AiFillSetting></AiFillSetting>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-courses"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">دوراتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <FaListAlt></FaListAlt>
                      </IconContext.Provider>
                    </Link>
                    {store.user && store.user.is_staff && (
                      <a
                        href="https://admin.farashawake.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                      >
                        <span className="">لوحة التحكم</span>
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#fff" } }}
                        >
                          <FaChalkboardTeacher></FaChalkboardTeacher>
                        </IconContext.Provider>
                      </a>
                    )}
                    <Link
                      to="/my-orders"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">طلباتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <FaSellsy></FaSellsy>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-pack-orders"
                      className="text-white flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className=""> طلباتي حزمة</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#fff" } }}
                      >
                        <FaSellsy></FaSellsy>
                      </IconContext.Provider>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {store.isLogged && (
              <div
                onClick={() => {
                  setStore({
                    ...store,
                    isLogged: false,
                  });
                  deleteTheUserAndTokenFromStorage();
                }}
                className="hover:text-gray-600 cursor-pointer"
              >
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#492b83" } }}
                >
                  <FiLogOut></FiLogOut>
                </IconContext.Provider>
              </div>
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://store.farashawake.com/"
              className=" cursor-pointer bg-orange py-2 px-6  rounded-4xl font-black text-primary text-white text-base space-x-1"
            >
              <StoreIcon />
              <span>المتجر</span>
            </a>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Header;
