import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { addToNewsletter } from "../services/newsletter";
import LoaderModal from "./LoaderModal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Footer = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <div className="text-center text-white bg-farasha-dark-purple">
      <div className="p-4 flex flex-col-reverse md:flex-row items-center justify-around">
        <div className="x-sm:w-full md:w-1/3 text-center ">
          
          
          
          <div className="w-full flex flex-col space-y-5 md:space-y-0 md:flex-row items-center justify-around">
            <div className="flex flex-row items-center justify-around">
              <a
                href="https://twitter.com/Rashameshta"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <TwitterIcon />
                </IconButton>
              </a>
              <a
                href="https://www.youtube.com/channel/UCT8ZKQUQcScBWHr-2_SdaLw"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <YouTubeIcon />
                </IconButton>
              </a>
              <a
                href="https://web.facebook.com/farashaascension/?_rdc=1&_rdr"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <FacebookIcon />
                </IconButton>
              </a>
              <a
                href="https://www.instagram.com/rashameshta/"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <InstagramIcon />
                </IconButton>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=213558318841"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <WhatsAppIcon />
                </IconButton>
              </a>
            </div>
          </div>
        </div>
        <div className="x-sm:w-full md:w-1/3 text-center ">
          
          
          
          <div className="w-full flex flex-col space-y-5 md:space-y-0 md:flex-row items-center justify-around">
            <div className="text-gray-200 text-center x-sm:text-xs 2xl:text-lg">
              2023 &copy; جميع الحقوق محفوظة لفراشة اسانشن
            </div>
          </div>
        </div>
        <div className="x-sm:hidden md:flex w-1/3 my-4 text-center">
          <div className="w-full flex flex-row items-center justify-center">
            <img
              className="x-sm:w-3/4 md:w-1/4"
              src="/images/logo_white.svg"
              alt="logo"
            />
          </div>
          {/* <a
            href="https://smartinia.com/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row space-x-4 items-center justify-center mt-6"
          >
            <img
              src="/images/footer/smartinia.png"
              className="x-sm:h-6 sm:h-10"
              alt="icon"
            />
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              تم الانشاء بواسطة{" "}
            </p>
          </a> */}
        </div>
        
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Footer;
