import React from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";

const LoaderModal = ({ modalIsOpen }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        overlay: {
          zIndex: 1000,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          borderColor: "rgba(0, 0, 0, 0)",
        },
      }}
    >
      <Loader type="Oval" height={200} width={200} color="#fed7e2" />
    </Modal>
  );
};

export default LoaderModal;
