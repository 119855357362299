import React from "react";
import Header from "../Header";
import Overview from "./content/Overview";

import Footer from "../Footer";

const Page = () => {
  return (
    <>
      <Header />
      <Overview></Overview>
      <div className="home w-full xl:w-9/12 mx-auto px-4" style={{marginBottom:50}}>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Page;
