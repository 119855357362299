import React, { useContext } from "react";
import { storeContext } from "./../global/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import { LastLocationProvider } from "react-router-last-location";
import HomePage from "../components/HomePage/Page";
import { IconButton } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";


function App() {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <LastLocationProvider>
          <ScrollToTop />
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <HomePage {...props}></HomePage>}
            ></Route>
            <Redirect to="/"></Redirect>
          </Switch>
        </LastLocationProvider>
      </Router>
      <a
        href="https://api.whatsapp.com/send?phone=213558318841"
        className="whatsapp flex flex-row items-start justify-center"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton style={{ color: "white" }}>
          <WhatsAppIcon />
        </IconButton>
      </a>
    </React.Fragment>
  );
}

export default App;
