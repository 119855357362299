import React, { useState } from "react";
import { Link } from "react-router-dom";
import banner from "../../../assets/BanerMain.jpg";
import banner2 from "../../../assets/baner3.png";
import banner6 from "../../../assets/baner6.jpg";
import banner7 from "../../../assets/baner7.png";
import ticket from "../../../assets/tiket.png";
import clock from "../../../assets/clock.png";
import calender from "../../../assets/calender.png";
const Overview = () => {
  return (
    <div className="py-5  ">
      <img style={{
        width: "100vw",
        maxWidth: "100%",
        height: "auto",
        maxHeight: "75vh",
        minHeight: "20vh"
      }} src={banner} alt="baner" />
      <div className="flex  py-8 flex-col items-center justify-center text-white">
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose max-w-xl">الحدث الكبير لهذا العام</p>
        <p className="gold ">LES ARCHITECTES DE LA REALITE</p>
        <p className="text-purple px-8  rounded-xl mb-12  text-center text-2xl 2xl:text-5xl font-DimaShekari">من تقديم الدكتورة رشا مشتة</p>
      </div>
      <div className="Baner2 flex  py-8 flex-col items-center justify-center text-white">
        <div className="GoldenB">
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">,مع نهاية 2023 وحلول 2024 ممكن تكون شخص مخطط لأهدافك وحياتك</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">وممكن تكون شخص لا يعرف التخطيط، وفي كلتا الحالتين</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">يجب أن تدرك أن حياتك (او الواقع الذي تعيشه) عبارة عن مجسم</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">!!وانت المهندس الذي يصمم هذا المجسم</p>
        </div>
      </div>
      <div dir="rtl" className=" flex  py-8 flex-col items-center justify-center text-white">
        <div className="text-farasha-purple">
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">وحتى يتجسد هذا المجسم بشكل ناجح يجب أن تقوم بتصميمه وفقا للقوانين الصحيحة وبالطريقة الصحيحة.</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">
            اذا لم تدرك أنك أنت من تملك الكونترول في صناعة هذا المجسم أو حياتك بنسبة كبيرة
          </p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">ستبقى ضحية الأحداث الخارجية التي ستوجهك حتما نحو طرق واحتمالات لا تمثلك</p>
        </div>
      </div>
      <img style={{
        width: "100vw",
        maxWidth: "100%",
        height: "auto",

      }} src={banner2} alt="baner" />
      <div className="text-farasha-purple py-8">
        <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">لأول مرة</p>
        <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">
          اشارك معك معلومات جد قيمة تساعدك على بداية سنة 2024 بوعي مختلف وطاقة مرتفعة
        </p>
        <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">
          :يوم تدريبي مع تمارين عملية تساعدك على معرفة نفسك اكثر والاجابة على هذه الأسئلة 
        </p>
      </div>
      <div className=" flex  py-8 flex-col items-center justify-center text-white">
        <div className="GoldenB2 flex  py-8 flex-col md:flex-row text-right text-base  2xl:leading-loose  xl:leading-10">
          <div className="flex-35 flex flex-col items-center justify-center text-white ">
            <ul dir="rtl" style={{ listStyle: "inside" }}>
              <li>كيف يمكنني ان اوظف هذه الوسائل ضمن مخطط عملي وفعال يساعدني في تحقيق انجازات ملموسة خلال سنة 2024؟</li>
              <li>ماهو الواقع؟ كيف اعرف افضل احتمال لي في هذا الواقع وكيف اتصل به؟</li>
              <li>ماهي قوانين التمكين؟</li>
            </ul>
          </div>
          <div className="flex-45 relative mb-4 md:mb-0 ">
            <ul dir="rtl" style={{ listStyle: "inside" }}>
              <li>إلى اين أريد الذهاب؟</li>
              <li>هل أنا في الطريق الصحيح مع أهدافي؟</li>
              <li>ماهي الوسائل التي تساعدني في الوصول لوجهتي؟</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex  py-8 flex-col items-center justify-center text-white">
        <p className=" text-farasha-purple text-base text-center 2xl:text-2xl 2xl:leading-loose">
          فهل حان الوقت لتبدأ السنة الجديدة بقصة جديدة
        </p>
        <p className="text-farasha-purple text-base 2xl:text-2xl text-center 2xl:leading-loose mb-5">
          تحمل داخلها كل اسرار وطاقة صناعة الواقع؟ هذا ما سنتعلمه خلال إيفنت
        </p>
        <p className="gold ">LES ARCHITECTES DE LA REALITE</p>
        <p className="text-farasha-purple text-base 2xl:text-2xl text-center 2xl:leading-loose mt-5">
          الذي ستتعلم فيه وسائل وأسرار صناعة الواقع
        </p>
      </div>
      <div className="Baner4 flex   flex-col items-center justify-center text-white">
        <div className=" tets flex  py-8 flex-col items-center justify-center text-white" style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: "100%",
          minHeight: "75vh"
        }}>
          <div className="flex flex-col">
            <p className="text-lg 2xl:text-3xl 2xl:leading-loose  text-center  xl:leading-10">الجلسات والتأملات</p>
            <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center   xl:leading-10">ستكون هناك جلسات وتأملات تساعدك على التحرر</p>
            <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center  xl:leading-10">من معيقات الانجاز وشحن ورفع طاقتك بتقنيات عديدة</p>

            <div className=" flex  flex-col md:flex-row text-right text-base  2xl:leading-loose  xl:leading-10 mt-10 mr-5">
              <div className="flex-45 flex flex-col  text-white ">
                <ul dir="rtl" style={{ listStyle: "inside" }}>
                  <li>سحب طاقي</li>
                  <li>تمرين التحرر من الخوف</li>
                </ul>
              </div>
              <div className="flex-55  ">
                <ul dir="rtl" style={{ listStyle: "inside" }}>
                  <li>جلسة ثيتاهيلينغ</li>
                  <li>جلسة الاتصال باحتمالك الأفضل</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="GoldenB3">
        <div className=" flex   items-center justify-center content-center">
          <a className="mt-4 cursor-pointer bg-purple  py-2 px-6 xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base"
            href="https://wa.me/213558318841"
          >اشترك الآن </a>

        </div>
      </div>
      <div className="Baner5 flex  py-8 flex-col items-center justify-center text-white">
        <div >
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">الأهم من كل هذا هو الطاقة المليئة بالبهجة والمتعة والضحك</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">التي ستكون حاضرة خلال هذا الحدث والتي تجعلك ترى وتتصل بأفضل نسخة منك</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">سنخلق مع بعض أجواء من الاثارة والحماس والحب</p>
          <p className="text-base 2xl:text-2xl 2xl:leading-loose  text-center md:text-right  xl:leading-10">التي تساعدنا على استقبال السنة الجديدة بطاقة عالية</p>
        </div>
      </div>


      <div className="flex  py-8 flex-col items-center justify-center text-white">
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose ">سيكون هدية لكل المشاركين؛ كتيب عملي يحتوى على اسرار وتطبيقات قيمة</p>
        <p className="gold ">Money Symphony هدية الحدث ! كتيب</p>
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose">تساهم لك في رفع وعيك المالي وتحقيق الثراء المادي بطرق سهلة</p>
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose">و بسيطة لكنها جد عميقة</p>
      </div>
      <div className="flex  py-8 flex-col items-center justify-center">
        <img style={{
          width: "auto",
          maxWidth: "100%",
          height: "auto"
        }} src={banner7} alt="baner7" />
      </div>
      <div className="Baner9 flex  py-8 flex-col md:flex-row  items-center justify-around text-white content-center">
        <div className="flex flex-col" >
          <img style={{
            width: "auto",
            maxWidth: "100%",
            height: "auto"
          }} src={ticket} alt="ticket" />
          <div className=" flex   items-center justify-center text-white content-center">
          <span> دج </span> <span style={{
            padding:"0px 3px"
          }}> 4500 </span> </div>
        </div>
        <div className="flex flex-col items-center" >
          <img style={{
            width: "auto",
            maxWidth: "40%",
            height: "auto"
          }} src={clock} alt="ticket" />
          <div className=" flex   items-center justify-center text-white content-center">
            10:00 – 16:00
          </div>
        </div>
        <div className="flex flex-col items-center" >
          <img style={{
            width: "auto",
            maxWidth: "40%",
            height: "auto"
          }} src={calender} alt="ticket" />
          <div  className=" flex   items-center justify-center text-white content-center">
          2023 <span style={{
            padding:"0px 3px"
          }}>{' '} ديسمبر {' '}</span> 30   </div>
        </div>
      </div>
      <div className=" flex   items-center justify-center content-center">
        <a className="mt-4 cursor-pointer GoldenB2   xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base"
          href="https://wa.me/213558318841"
        >اشترك الآن و احجز مقعدك</a>

      </div>
    </div>
  );
};

export default Overview;

